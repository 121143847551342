import { useOs } from '@wppopen/react'
import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Action from 'app/components/action'
import Category from 'app/components/category'
import Confirmation from 'app/components/confirmation'
import Form from 'app/components/form'
import Header from 'app/components/header'
import RejectBanner from 'app/components/review/reject/rejectBanner'
import Status from 'app/components/review/status'
import Spinner from 'app/components/spinner/Spinner'
import Stepper from 'app/components/stepper'
import { ApplicationError, WppGrid, WppStepper, WppTypography } from 'buildingBlocks'
import { APP_ACTIONS, STEP_ACTIONS, TOAST_MESSAGE_TYPES } from 'config/enums'
import ICategory from 'interfaces/category/ICategory'
import IFieldComment from 'interfaces/field/fieldComment/IFieldComment'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import ChangeLog from 'pages/changeLog'
import styles from 'pages/questionnaire/Questionnaire.module.scss'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import { setAppStatus, setState } from 'store/reducers/questionnaireSlice'
import FormHelper from 'utils/form/FormHelper'
import FieldCommentHelper from 'utils/formField/FieldCommentHelper'
import FormFieldHelper from 'utils/formField/FormFieldHelper'
import ActionHelper from 'utils/questionnaire/ActionHelper'
import QuestionnaireHelper from 'utils/questionnaire/QuestionnaireHelper'

/**
 * Questionnaire app
 */
const Questionnaire: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  const location = useLocation()
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)
  const projectBriefState = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const projectQuestionnaireId: string = _.toString(projectBriefState.questionnaire.projectQuestionnaireId)
  const { osContext, osApi } = useOs()

  const ACCESS_TOKEN = osApi.getAccessToken()
  const dispatch = useDispatch<AppDispatch>()
  const { app, appStatus, activeStep, error, loading } = questionnaireState

  /**
   * Handle Stepper change
   * @param {number} stepNumber
   */
  const handleStep = (stepNumber: number, stepAction: STEP_ACTIONS, nextCategory: ICategory) => {
    QuestionnaireHelper.handleStep({
      accessToken: ACCESS_TOKEN,
      dispatch,
      appContext,
      projectQuestionnaireId,
      questionnaireState,
      stepAction,
      stepNumber,
      nextCategory,
      t,
    })
  }

  /**
   * Handle app actions i.e Submit, Save Progress, Cancel
   * @param {string} appAction
   * @param {(message: TOAST_MESSAGE_TYPES) => void} callback
   * @param {boolean} displayMessage
   */
  const handleAppAction = async (
    appAction: string,
    callback: (message: TOAST_MESSAGE_TYPES) => void,
    displayMessage: boolean = true,
  ): Promise<void> => {
    const updatedQuestionnaire = await ActionHelper.handleAppAction(
      {
        accessToken: ACCESS_TOKEN,
        appAction,
        dispatch,
        displayMessage,
        appContext,
        questionnaireState,
        projectQuestionnaireId,
        t,
      },
      callback,
    )

    if (
      updatedQuestionnaire &&
      (_.isEqual(appAction, APP_ACTIONS.SAVE_AND_EXIT) || _.isEqual(appAction, APP_ACTIONS.CANCEL)) &&
      !updatedQuestionnaire.appStatus.isAppTouched &&
      updatedQuestionnaire.app?.isValid
    ) {
      const timer = setTimeout(
        () => {
          window.history.pushState(
            null,
            '',
            `${osContext.tenant.homeUrl}orchestration/project/${appContext.projectId}/workflow`,
          )

          clearTimeout(timer)
        },
        _.isEqual(appAction, APP_ACTIONS.CANCEL) ? 0 : 1500,
      )
    }
  }

  const initLoad = useCallback(async () => {
    QuestionnaireHelper.initLoad(
      ACCESS_TOKEN,
      appContext,
      questionnaireState,
      projectBriefState,
      projectQuestionnaireId,
      dispatch,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleComment = (field: IField, category: ICategory, value: string) => {
    FieldCommentHelper.addComment({
      accessToken: ACCESS_TOKEN,
      category,
      field,
      projectQuestionnaireId,
      appContext,
      questionnaireState,
      dispatch,
      tenantId: appContext.tenantId,
      value,
      t,
    })
  }

  /**
   * Handle form field change
   * @param {IFieldChangeEvent} event
   */
  const handleChange = (event: IFieldChangeEvent): void => {
    if (!app) return
    if (!FormFieldHelper.isValidChange(event)) return

    const formHelper = new FormHelper()
    const UpdatedApp = formHelper.updateApp(app, event)

    dispatch(
      setState({
        ...questionnaireState,
        appStatus: {
          ...questionnaireState.appStatus,
          isAppTouched: true,
        },
        app: UpdatedApp,
      }),
    )
  }

  const handleCommentMode = () => {
    QuestionnaireHelper.handleCommentMode(questionnaireState, dispatch)
  }

  const setFormFieldComments = (field: IField, fieldComment: IFieldComment) => {
    FieldCommentHelper.setCommentsForFormField(app, field, fieldComment, dispatch)
  }

  useEffect(() => {
    initLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    return <ApplicationError />
  }

  if (_.isNull(app)) {
    return <Spinner />
  }

  return (
    <>
      {_.isEqual(location.pathname, '/change-log') && <ChangeLog />}
      {_.isEqual(location.pathname, '/') && (
        <>
          <RejectBanner />
          <Header
            component={
              <div className={styles.headerItem}>
                <div className={styles.item}>
                  <WppTypography tag="h3" type="m-strong">
                    {app.appName}
                  </WppTypography>
                  <Status />
                </div>
                <div>
                  {!_.isEmpty(app.categories) && (
                    <div className={styles.actions}>
                      <Action handleAppAction={handleAppAction} handleCommentMode={handleCommentMode} />
                    </div>
                  )}
                </div>
              </div>
            }
          />
          <WppGrid container className={styles.layout} rowSpacing={2}>
            {!_.isEmpty(app.categories) && !_.isEqual(app.categories.length, 1) && (
              <WppGrid className={styles.layoutCategories} item all={4}>
                <WppStepper activeStep={activeStep}>
                  {app.categories.map((category: ICategory) => (
                    <Category key={category.id} category={category} handleStep={handleStep} />
                  ))}
                </WppStepper>
              </WppGrid>
            )}
            {!_.isEmpty(app.categories) && (
              <WppGrid item all={!_.isEqual(app.categories.length, 1) ? 20 : 24}>
                <div className={styles.formContainer}>
                  <Form
                    handleChange={handleChange}
                    handleComment={handleComment}
                    setFormFieldComments={setFormFieldComments}
                  />
                  <Stepper handleAppAction={handleAppAction} handleStep={handleStep} />
                </div>
              </WppGrid>
            )}
          </WppGrid>
          <Confirmation
            title={`Leave ${app.appName} form`}
            body={`Are you sure you want to leave your ${app.appName} form? Your progress will be lost.`}
            btnPrimaryText="Leave"
            btnSecondaryText="Cancel"
            handlePrimaryAction={() =>
              window.history.pushState(
                null,
                '',
                `${osContext.tenant.homeUrl}orchestration/project/${appContext.projectId}/workflow`,
              )
            }
            handleSecondaryAction={() => dispatch(setAppStatus({ ...appStatus, openModal: false }))}
            isOpen={appStatus.openModal}
          />
        </>
      )}
      {loading && <Spinner />}
    </>
  )
}

export default Questionnaire
